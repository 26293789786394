<template>
  <div id="logisticsinformation">
    <div class="logisticsinformation">
      <van-tabs v-model="active" @change="tabChange">
        <van-tab title="已发商品清单" name="shopgoods">
          <div v-show="list.length">
            <van-card v-for="item in list" :key="item.id">
              <template #title>
                <span>{{ item.itemName }}</span>
              </template>
              <template #thumb>
                <van-image :src="findImg(item)" />
                <!-- <video @click.stop="(e)=>videoStop(e)" v-if="findFileType(item)===2" :src="findUrl(item)" controls class="videoStyle"></video> -->
              </template>
              <template #price>
                <span>￥{{ (item.actualPrice || 0).toFixed(2) }}</span>
              </template>
              <template #desc>
                <p>{{item.skuSaleProperty}}</p>
              </template>
              <template #num>
                  <span>本次发货:</span>
                <span>{{item.alreadySkuQuantity}}</span>
              </template>
            </van-card>
            <!-- </van-list> -->
          </div>
        </van-tab>
        <van-tab title="物流信息" name="logisitics">
          <van-empty description="未查询到物流消息"
        /></van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getOrderBusinessDeliverDetail } from "./service";
export default {
  data() {
    return {
      active: 2,
        list:[],
    };
  },
  mounted() {
    this.determinePageSource();
    this.logisticshop();
  },
  methods: {
      findImg(item){
          return item?.mediaList[0]?.url ?? ''
      },
    //发现商品
    logisticshop() {
      getOrderBusinessDeliverDetail(this.$route.query.orderNo,this.$route.query.resultId)
        .then((res) => {
          if (res.status === "0") {
              this.list = res.data
          } else {
            Toast(res.msg);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    //默认
    determinePageSource() {
      const {
        query: { type },
      } = this.$route;
      const typeMappingTabName = {
        defaultSearch: "shopgoods",
        logisitics: "logisitics",
        logisticsinformation: "shopgoods",
      };
      if (type) {
        this.active = typeMappingTabName[type];
      } else {
        this.active = "shopgoods";
      }
    },
    tabChange(value) {
      this.active = value;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
</style>
<style lang="less" scoped>
#logisticsinformation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .logisticsinformation {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: @BackgroundColor;
    .van-tabs__content {
      width: 100%;
      height: calc(~"100% - 45px");
      overflow: auto;
    }
    .van-tab__text {
      font-size: 16px;
      font-family: PingFangSC, PingFang;
    }
    .van-tab--active {
      color: #0095da;
    }
    .van-tabs__line {
      background-color: #0095da;
      width: 95px;
    }
    .van-tab__pane {
      height: 100%;
      overflow: auto;
      padding: 12px;
    }
    .van-card {
      border-radius: 8px;
      background-color: #fff;
      .van-card__thumb {
        width: 99px;
        height: 99px;
      }
      .van-card__content {
        margin-left: 4px;
        .van-card__title {
          width: 100%;
          height: 48px;
          max-height: 48px;
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
          font-family: PingFangSC, PingFangSC-Medium;
          color: #000;
          overflow: hidden;
          display: -webkit-box; //将元素设为盒子伸缩模型显示
          -webkit-box-orient: vertical; //伸缩方向设为垂直方向
          -webkit-line-clamp: 2; //超出2行隐藏，并显示省略号
        }
        .van-card__price {
          font-size: 16px;
          font-family: Montserrat, Montserrat-Medium;
          font-weight: 500;
          color: #ff3228;
        }
      }
    }
  }
}
</style>